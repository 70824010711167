import {
  Flex,
  Input,
  InputGroup,
  InputLeftAddon,
  InputRightElement,
  Text,
  Textarea,
  VStack,
} from "@chakra-ui/react";
import React from "react";

const CustomInput = ({
  label,
  left,
  right,
  error,
  placeholder,
  isInvalid,
  type,
  onChange,
  value,
  textonly,
  area,
  max,
  min,
  maxL,
  rightButton,
  id,
  initialRef,
  disabled,
  rightButton2,
  color= "#fff",
}) => {
  // const initialRef = React.useRef(null);
  return (
    <VStack spacing={0} align={"start"} w={"full"}>
      <Text fontSize={"md"} fontWeight={"normal"} my={2} color={color}>
        {label}
      </Text>
      <Flex w={"full"} gap={4} m={0} position={"relative"}>
        <InputGroup w={"full"}>
          {left && <InputLeftAddon children={left} />}
          {textonly ? (
            <Text
              bg={"#fff"}
              flex={1}
              w={"full"}
              py={2}
              mb={0}
              px={4}
              borderRadius={5}
              color={""}
            >
              {value}
            </Text>
          ) : area ? (
            <Textarea
              ref={initialRef}
              placeholder={placeholder}
              type={type}
              isInvalid={isInvalid}
              errorBorderColor="red.300"
              value={value}
              onChange={onChange}
              bg={"#fff"}
              color={"#000"}
            />
          ) : (
            <Input
              id={id}
              w={"full"}
              maxW={"full"}
              ref={initialRef}
              placeholder={placeholder}
              type={type}
              isInvalid={isInvalid}
              errorBorderColor="red.300"
              value={value}
              onChange={onChange}
              max={max}
              min={min}
              maxLength={maxL}
              bg={"#fff"}
              color={"#000"}
              disabled={disabled}
            />
          )}
          {right && <InputRightElement>{right}</InputRightElement>}
        </InputGroup>
        {/* <div className="right_btn_1">
          {rightButton}
        </div> */}
        {rightButton}
        {rightButton2 && <div className="right_btn_2">
          {rightButton2}
        </div>
        }
      </Flex>
      {error && (
        <Text fontSize={"sm"} color={"red.300"} px={2}>
          {error}
        </Text>
      )}
    </VStack>
  );
};

export default CustomInput;
