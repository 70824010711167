import {
  Center,
  Container,
  Flex,
  Heading,
  Image,
  Td,
  Tr,
  useDisclosure,
  Link,
  Select,
  Input,
  Text,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import TableView from "../../components/TableView";
import { useNavigate } from "react-router-dom";
import { FaFileDownload } from "react-icons/fa";
import { FiEye, FiShare2, FiTrash2 } from "react-icons/fi";
import { BiEditAlt } from "react-icons/bi";
import Loader from "../../components/Loader";
import store from "../../redux/Store";
import { getPolicy_list } from "../../redux/slice/policySlice";
import { useSelector } from "react-redux";
import ImageModal from "../../components/ImageModal";
import BreadCrumName from "../../components/BreadCrumName";
import moment from "moment";
import CustomPopup from "../../components/CustomPopup";
import { decryptData, downloadImage } from "../../utils/utils";
import { addUserPolicy } from "../../utils/apis";
import ShareOptions from "../../components/ShareOptions";

const ArchivedPolicy = () => {
  const navigate = useNavigate();
  const loggedInUser = localStorage.getItem("user");
  const user = JSON.parse(loggedInUser);
  const ID = user?.userid;
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { policy_list, p_loading } = useSelector((state) => state.policy);
  const [loading, setLoading] = useState(false);
  const [index, setIndex] = useState("");
  const [id, setId] = useState("");
  const {
    isOpen: isPOpen,
    onOpen: onPOpen,
    onClose: onPClose,
  } = useDisclosure();
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(5);
  const [search, setSearch] = useState("");
  const [initialValue, setInitialValue] = useState(1);
  const [totalCount, setTotalCount] = useState(policy_list?.length);

  const filterCount = () => {
    const count = data.filter((v) => {
      const formats = ["DD/MM/YYYY", "YYYY/MM/DD", "YYYY-MM-DD", "DD-MM-YYYY"];
      let matchFound = false;

      formats.forEach((format) => {
        const formattedSearch = moment(search, format, true);
        const expiryDate = moment(v.expiry_date, "YYYY-MM-DD", true);
        if (
          formattedSearch.isValid() &&
          expiryDate.isValid() &&
          formattedSearch.isSame(expiryDate, "day")
        ) {
          matchFound = true;
        }
      });
      return (
        v.name.toLowerCase().includes(search.toLowerCase()) ||
        v.policy_company.toLowerCase().includes(search.toLowerCase()) ||
        v.policy_number.toLowerCase().includes(search.toLowerCase()) ||
        v.insurance_type.toLowerCase().includes(search.toLowerCase()) ||
        v.policy_plan.toLowerCase().includes(search.toLowerCase()) ||
        moment(v?.expiry_date)?.format("DD/MM/YYYY").includes(search) ||
        moment(v?.expiry_date)?.format("YYYY/MM/DD").includes(search) ||
        moment(v?.expiry_date)?.format("DD-MM-YYYY").includes(search) ||
        moment(v?.expiry_date)?.format("DD/MM/YY").includes(search) ||
        moment(v?.expiry_date)?.format("MM/DD/YY").includes(search) ||
        moment(v?.expiry_date)?.format("YY/MM/DD").includes(search) ||
        moment(v?.expiry_date)?.format("DD MMM YYYY").includes(search) ||
        moment(v?.expiry_date)?.format("DD MMMM YYYY").includes(search) ||
        moment(v?.expiry_date)?.format("YYYY MMM DD").includes(search) ||
        moment(v?.expiry_date)?.format("YYYY MMMM DD").includes(search) ||
        matchFound
      );
    });

    setTotalCount(count?.length);
  };
  const todayDate = new Date();
  todayDate.setDate(todayDate.getDate() - 1);
  const data = policy_list.filter((item) => {
    return (
      moment(item.expiry_date).format("YYYY-MM-DD  HH:mm:ss") <
      moment(todayDate).format("YYYY-MM-DD  HH:mm:ss")
    );
  });
  const delete_policy = () => {
    onPClose();
    const body = new FormData();
    body.append("action", "delete");
    body.append("userid", user.userid);
    body.append("id", id);
    addUserPolicy(body, setLoading).then(
      (v) => v.success && store.dispatch(getPolicy_list(user.userid))
    );
  };
  useEffect(() => {
    localStorage.getItem("user");
    store.dispatch(getPolicy_list(user.userid));
    filterCount();
  }, []);
  useEffect(() => {
    filterCount();
  }, [search]);
  return (
    <Container maxW={"container.xl"} w={"full"} mt={5}>
      <BreadCrumName
        current={"Archived Policy"}
        titlelist={[{ name: "Home", to: "/" }]}
      />
      <Flex align={"center"} justify={"space-between"} mb={5} mt={4}>
        <Heading
          fontSize={["md", "lg", "xl", "2xl"]}
          fontWeight={"medium"}
          color={"#fff"}
        >
          Archived Policies
        </Heading>
      </Flex>
      {data?.length == 0 || (
        <Flex
          gap={2}
          flexDirection={["column", "column", "row"]}
          justifyContent={"space-between"}
          mb={3}
        >
          <Flex gap={2} alignItems={"center"} alignSelf={"start"} color={"#fff"}>
            <Text>Show</Text>
            <Select
            color={"#000"}
              W={"44"}
              backgroundColor={"#fff"}
              size={"sm"}
              borderRadius={5}
              value={limit}
              onChange={(e) => {
                setLimit(e.target.value);
                setPage(1);
              }}
            >
              <option value="5">5</option>
              <option value="10">10</option>
              <option value="25">25</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </Select>
            <Text>entries</Text>
          </Flex>
          <Flex  gap={2} alignItems={"center"} color={"#fff"}>
            <Text>Search:</Text>
            <Input
              placeholder="Search policy"
              w={"xs"}
              size="md"
              color={"#000"}
              backgroundColor={"#fff"}
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          </Flex>
        </Flex>
      )}
      {p_loading && data?.length !== 0 ? (
        <Loader />
      ) : data?.length == 0 ? (
        <Center flexDirection={"column"}>
          <Image src={"no-data1.png"} w={["xs", "sm"]} objectFit={"contain"} />
        </Center>
      ) : (
        <>
          <TableView
            headData={[
              "Name",
              "Company",
              "Insurance",
              "Plan/Registration",
              "Policy no.",
              "Expiry Date",
              "Action",
            ]}
            body={data
              // .filter((v) => {
              //   return (
              //     v.name.toLowerCase().includes(search.toLowerCase()) ||
              //     v.policy_company
              //       .toLowerCase()
              //       .includes(search.toLowerCase()) ||
              //     v.policy_number
              //       .toLowerCase()
              //       .includes(search.toLowerCase()) ||
              //     v.insurance_type
              //       .toLowerCase()
              //       .includes(search.toLowerCase()) ||
              //     v.policy_plan.toLowerCase().includes(search.toLowerCase())
              //   );
              // })
              .filter((v) => {
                const formats = [
                  "DD/MM/YYYY",
                  "YYYY/MM/DD",
                  "YYYY-MM-DD",
                  "DD-MM-YYYY" /* Add other formats as needed */,
                ];
                let matchFound = false;

                formats.forEach((format) => {
                  const formattedSearch = moment(search, format, true);
                  const expiryDate = moment(v.expiry_date, "YYYY-MM-DD", true);
                  if (
                    formattedSearch.isValid() &&
                    expiryDate.isValid() &&
                    formattedSearch.isSame(expiryDate, "day")
                  ) {
                    matchFound = true;
                  }
                });
                return (
                  v.name.toLowerCase().includes(search.toLowerCase()) ||
                  v.policy_company
                    .toLowerCase()
                    .includes(search.toLowerCase()) ||
                  v.policy_number
                    .toLowerCase()
                    .includes(search.toLowerCase()) ||
                  v.insurance_type
                    .toLowerCase()
                    .includes(search.toLowerCase()) ||
                  v.policy_plan.toLowerCase().includes(search.toLowerCase()) ||
                  moment(v?.expiry_date)
                    ?.format("DD/MM/YYYY")
                    .includes(search) ||
                  moment(v?.expiry_date)
                    ?.format("YYYY/MM/DD")
                    .includes(search) ||
                  moment(v?.expiry_date)
                    ?.format("DD-MM-YYYY")
                    .includes(search) ||
                  moment(v?.expiry_date)?.format("DD/MM/YY").includes(search) ||
                  moment(v?.expiry_date)?.format("MM/DD/YY").includes(search) ||
                  moment(v?.expiry_date)?.format("YY/MM/DD").includes(search) ||
                  moment(v?.expiry_date)
                    ?.format("DD MMM YYYY")
                    .includes(search) ||
                  moment(v?.expiry_date)
                    ?.format("DD MMMM YYYY")
                    .includes(search) ||
                  moment(v?.expiry_date)
                    ?.format("YYYY MMM DD")
                    .includes(search) ||
                  moment(v?.expiry_date)
                    ?.format("YYYY MMMM DD")
                    .includes(search) ||
                  matchFound
                );
              })
              .slice(initialValue - 1, limit * page)
              .map((v, i) => (
                <Tr>
                  <Td textAlign={"center"}>{v.name}</Td>
                  <Td textAlign={"center"}>{v.policy_company}</Td>
                  <Td textAlign={"center"}>{v.insurance_type}</Td>
                  <Td textAlign={"center"}>{v.policy_plan}</Td>
                  <Td textAlign={"center"}>{v.policy_number}</Td>
                  <Td textAlign={"center"}>
                    {moment(v.expiry_date).format("DD/MM/YYYY")}
                  </Td>
                  <Td textAlign={"center"}>
                    {
                      <Flex align={"center"} gap={2} justify={"center"}>
                        <FiEye
                          size={20}
                          cursor={"pointer"}
                          onClick={() => {
                            setIndex(i);
                            onOpen();
                          }}
                        />
                        <BiEditAlt
                          size={20}
                          cursor={"pointer"}
                          onClick={() => navigate("/edit-policy", { state: v })}
                        />
                        {/* <ShareOptions icon url={decryptData(v?.policy_file)} /> */}
                        <FiTrash2
                          size={20}
                          cursor={"pointer"}
                          onClick={() => {
                            setId(v.id);
                            onPOpen();
                          }}
                        />
                        {v.policy_file.split(".").pop().trim() === "pdf" ? (
                          <Link
                            href={decryptData(v.policy_file)}
                            download
                            isExternal
                          >
                            <FaFileDownload size={20} cursor={"pointer"} />
                          </Link>
                        ) : (
                          <FaFileDownload
                            size={20}
                            cursor={"pointer"}
                            onClick={() =>
                              downloadImage(decryptData(v.policy_file))
                            }
                          />
                        )}
                      </Flex>
                    }
                  </Td>
                </Tr>
              ))}
          />
          {data?.filter((v) => {
            const formats = [
              "DD/MM/YYYY",
              "YYYY/MM/DD",
              "YYYY-MM-DD",
              "DD-MM-YYYY",
            ];
            let matchFound = false;

            formats.forEach((format) => {
              const formattedSearch = moment(search, format, true);
              const expiryDate = moment(v.expiry_date, "YYYY-MM-DD", true); // Assuming expiry_date is in 'YYYY-MM-DD' format

              if (
                formattedSearch.isValid() &&
                expiryDate.isValid() &&
                formattedSearch.isSame(expiryDate, "day")
              ) {
                matchFound = true;
              }
            });

            return (
              v.name.toLowerCase().includes(search.toLowerCase()) ||
              v.policy_company.toLowerCase().includes(search.toLowerCase()) ||
              v.policy_number.toLowerCase().includes(search.toLowerCase()) ||
              v.insurance_type.toLowerCase().includes(search.toLowerCase()) ||
              v.policy_plan.toLowerCase().includes(search.toLowerCase()) ||
              moment(v?.expiry_date)?.format("DD/MM/YYYY").includes(search) ||
              moment(v?.expiry_date)?.format("YYYY/MM/DD").includes(search) ||
              moment(v?.expiry_date)?.format("YYYY-MM-DD").includes(search) ||
              moment(v?.expiry_date)?.format("DD-MM-YYYY").includes(search) ||
              moment(v?.expiry_date)?.format("DD/MM/YY").includes(search) ||
              moment(v?.expiry_date)?.format("MM/DD/YY").includes(search) ||
              moment(v?.expiry_date)?.format("YY/MM/DD").includes(search) ||
              moment(v?.expiry_date)?.format("DD MMM YYYY").includes(search) ||
              moment(v?.expiry_date)?.format("DD MMMM YYYY").includes(search) ||
              moment(v?.expiry_date)?.format("YYYY MMM DD").includes(search) ||
              moment(v?.expiry_date)?.format("YYYY MMMM DD").includes(search) ||
              matchFound
            );
          }).length == 0 && (
            <Text
              p={2}
              py={5}
              textAlign={"center"}
              fontSize={"lg"}
              fontWeight={"600"}
              color={"#fff"}
            >
              No Policies Found
            </Text>
          )}
        </>
      )}
      <ImageModal
        isOpen={isOpen}
        onClose={onClose}
        url={decryptData(data[index]?.policy_file)}
      />
      <CustomPopup
        isOpen={isPOpen}
        onClick={() => delete_policy()}
        onClose={onPClose}
        b_name={"Delete"}
        title={"Delete"}
        mess={"Are you sure? You want to delete policy"}
      />
      {totalCount == 0 || (
        <Flex
          gap={2}
          flexDirection={["column", "row"]}
          justifyContent={"space-between"}
          mt={3}
          color={"#fff"}
        >
          <Text>{`Showing ${initialValue} to ${
            limit * page >= totalCount ? data?.length : page * limit
          } of ${data?.length} entries`}</Text>
          <Flex gap={2} color={"#fff"}>
            <Text
              cursor={page < 2 ? "not-allowed" : "pointer"}
              color={page < 2 ? "#fff" : "#FFD700"}
              fontSize={"lg"}
              fontWeight={"500"}
              onClick={() => {
                page > 1 && setPage(page - 1);
                page > 1 &&
                  setInitialValue(Number(initialValue) - Number(limit));
              }}
            >
              Previous
            </Text>
            <Text fontSize={"lg"} fontWeight={"500"}>
              {page}
            </Text>
            <Text
              cursor={limit * page >= totalCount ? "not-allowed" : "pointer"}
              color={limit * page >= totalCount ? "#fff" : "#FFD700"}
              fontSize={"lg"}
              fontWeight={"500"}
              onClick={() => {
                limit * page >= totalCount || setPage(page + 1);
                limit * page >= totalCount ||
                  setInitialValue(Number(initialValue) + Number(limit));
              }}
            >
              Next
            </Text>
          </Flex>
        </Flex>
      )}
    </Container>
  );
};

export default ArchivedPolicy;
